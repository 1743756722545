import React from 'react';

function FAQ() {
    return (
        <div>
            <h1>This page is empty</h1>
        </div>
    )
}

export default FAQ;